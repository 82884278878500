<template>
  <div class="about">
    <p
      v-for="(p, i) in about"
      :key="i"
      class="georgia text-xl leading-relaxed text-center pb-4"
      :class="i === 0 ? 'font-extrabold' : 'italic'"
      >
      {{ p }}
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      about: [
        'Hakkımızda',
        'Biz',
        'Çağdaş iletişim araçları ve modern teknoloji İnsanı yeni arayışlara,farklı ve sıra dışı duygulara itmektedir. Çağdaş ekonomi,insanı,makineleşmiş kalıplardan çıkarıp daha doğal,yeni ötesi,emekle birleşip tekrar derlenip toparlananlar insan yaşamını etkilemektedir.Daha özgün,bağımsız gerçek ötesi,zenaatle sanat arası,el emeği düş izi,özenle seçilmiş ahşap,ustalıkla birleşen ayrıntılar,zenaatle verilen çizgiler yalın, dingin dökümlü gölgeli ve öyküsel olmalıdır…kii modern teknolojiden öte daha içtenli hazlara ulaşılabilsin...',
        'Özgür ahşap tasarım ve sadelikten,uyarıcı tutkuya, doğal yapının verdiği tazelik ve berraklık, doğal koku, yaşama yeni tatlar, çizgi ötesi ilham kaynakları oluşturur… El emeği göz nuru ile oluşan üretim ve birleştirme, derleme toparlama yaşamın taa kendisidir. Sıradanlıktan öte, mükemmel bir zenaatin işçiliği, geçmeleri, köşeleri bağlantıları, ek yerleri ve parçaları gizemli bir duyarlığın sonucunu doğurur… İşte özgürahşap budur...',
      ],
    };
  },
};
</script>
