<template>
  <div class="production">
    <p
      v-for="(p, i) in production"
      :key="i"
      class="georgia text-xl leading-relaxed text-center pb-4"
      :class="i === 0 ? 'font-extrabold' : 'italic'"
      >
      {{ p }}
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      production: [
        'Üretim',
        'Özel Kanada kavağından, oküme, sedir, kayın gibi ağaçlardan üretmekte olduğumuz ahşap ambalaj kutularımız farklı sektörlerde amacına uygun, haklı bir rağbet görmektedir.',
        '1. Gıda ve şekerleme sektöründe, pasta ve çikolata kutuları, lokum, baklava, ezme, incir, kayısı, “çay standları”, şarap kutuları, zeytinyağı şişeleri için özel kutular.',
        '2. Sanayii sektöründe, kıymetli el aletleri ve tıpta, ilaç sanayiinde, seramik ürünlerin, tabak ve vazoların nakliyesinde ve sunumunda güvenle kullanacağınız kutu ve kasalar.',
        '3. Giyim sektöründe, ayakkabı, kemer, anahtarlık, cüzdan, gömlek, kravat, iç çamaşırı ve özel kokular için setler oluşturmaktayız. Bu gıda ürünlerindede, zeytinyağı, kuru incir, ceviz ve benzeri ürünlerle oluşturulan kutular...',
        '4. Özel günlerinizde, bayramlarda, yılbaşı, sevgililer günü ve çok özel hediyelikler için özel setler ve özel kolleksiyonlar oluşturmaktayız… “İstediğiniz ve düşündüğünüz herşeyi kutuya yerleştirebiliriz”.',
      ],
    };
  },
};
</script>
