<template>
  <div>
    <div class="border-t py-8 flex flex-col">
      <component
        :is="component.image ? 'img' : 'p'"
        v-for="(component, i) in content"
        :key="`'component-'${i}`"
        :src="component.image ? `images/${component.image}` : null"
        alt="Özgür Ahşap Kutu"
        title="Özgür Ahşap Kutu"
        class="pb-8"
        :class="component.text ? 'georgia italic text-xl leading-relaxed text-center' : null"
        >
        {{ component.text }}
      </component>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {},
  data() {
    return {
      content: [
        { image: 'ahsap-kutu-01.jpg' },
        { text: 'Sunumları daha zarif kılmak, değerini arttırmak ve karşınızdakine verdiğiniz hediyenin önemini vurgulamak açısından ambalajın değeri tartışılmaz.' },
        { image: 'IMG_5704.jpg' },
        { image: '3.jpg' },
        { image: '2.jpg' },
        { image: '1.jpg' },
        { image: '4.jpg' },
        { image: '7.jpg' },
        { image: '6.jpg' },
        { image: '5.jpg' },
        { image: 'MG_0219x.jpg' },
        { image: 'MG_0229x.jpg' },
        { image: 'MG_0234x.jpg' },
        { image: 'MG_0244x.jpg' },
        { image: 'MG_0252x.jpg' },
        { image: 'MG_0253x.jpg' },
        { image: 'MG_0258x.jpg' },
        { image: 'MG_0261x.jpg' },
        { image: 'MG_1089x.jpg' },
        { image: 'MG_1090x.jpg' },
        { image: 'MG_1111x.jpg' },
        { image: 'MG_1648x.jpg' },
        { image: 'MG_1655x.jpg' },
        { image: 'MG_1667x.jpg' },
        { image: 'MG_1673x.jpg' },
        { image: 'MG_0163x.jpg' },
        { image: 'MG_0167x.jpg' },
        { image: 'MG_0171x.jpg' },
        { image: 'MG_0188x.jpg' },
        { image: 'MG_0193x.jpg' },
        { image: 'MG_0204x.jpg' },
        { image: 'MG_0205x.jpg' },
        { image: 'ahsap-kutu-02.jpg' },
        { image: 'IMG_5711.jpg' },
        { text: 'Bu konuya yeni bir bakış açısı kazandırmak adına üretime başlayan “Özgür Ahşap” ambalaj sanayii ve ticaret limited şirketi isteyenlere sınırsız seçenek sunuyor.' },
        { image: 'IMG_5740.jpg' },
        { image: 'ahsap-kutu-03.jpg' },
        { text: 'Çağdaş iletişim araçları ve modern teknoloji İnsanı yeni arayışlara,farklı ve sıra dışı duygulara itmektedir.' },
        { image: 'ahsap-kutu-04.jpg' },
        { text: 'Çağdaş ekonomi,insanı,makineleşmiş kalıplardan çıkarıp daha doğal,yeni ötesi,emekle birleşip tekrar derlenip toparlananlar insan yaşamını etkilemektedir.' },
        { image: 'ahsap-kutu-05.jpg' },
        { image: 'IMG_5767.jpg' },
        { image: 'ahsap-kutu-06.jpg' },
        { image: 'IMG_5783.jpg' },
        { image: 'ahsap-kutu-07.jpg' },
        { image: 'IMG_5790.jpg' },
        { text: 'Daha özgün, bağımsız, gerçek ötesi, zanaatle sanat arası, el emeği düş izi, özenle seçilmiş ahşap kutular...' },
        { image: 'ahsap-kutu-08.jpg' },
        { image: 'IMG_5800.jpg' },
        { text: 'Ustalıkla birleşen ayrıntılar...' },
        { image: 'ahsap-kutu-09.jpg' },
        { text: 'Zanaatle verilen çizgiler...' },
        { image: 'IMG_5805.jpg' },
        { image: 'ahsap-kutu-10.jpg' },
        { text: 'Yalın, dingin, dökümlü gölgeli ve öyküsel olmalıdır...' },
        { image: 'IMG_5810.jpg' },
        { image: 'ahsap-kutu-11.jpg' },
        { text: 'Kii modern teknolojiden öte daha içtenli hazlara ulaşılabilsin...' },
        { image: 'ahsap-kutu-12.jpg' },
        { text: 'Özgür ahşap tasarım ve sadelikten, uyarıcı tutkuya, doğal yapının verdiği tazelik ve berraklık, doğal koku, yaşama yeni tatlar, çizgi ötesi ilham kaynakları oluşturur...' },
        { image: 'IMG_5824.jpg' },
        { image: 'ahsap-kutu-13.jpg' },
        { text: 'El emeği göz nuru ile oluşan üretim ve birleştirme, derleme toparlama yaşamın taa kendisidir.' },
        { image: 'IMG_5855.jpg' },
        { image: 'IMG_5864.jpg' },
        { image: 'ahsap-kutu-14.jpg' },
        { text: 'Sıradanlıktan öte, mükemmel bir zenaatin işçiliği, geçmeleri, köşeleri bağlantıları, ek yerleri ve parçaları gizemli bir duyarlığın sonucunu doğurur...' },
        { image: 'IMG_5870.jpg' },
        { image: 'ahsap-kutu-15.jpg' },
        { text: 'İşte Özgür Ahşap budur...' },
        { image: 'IMG_5883.jpg' },
        { image: 'IMG_5902.jpg' },
        { image: 'IMG_5908.jpg' },
        { image: 'ahsap-kutu-16.jpg' },
        { image: 'IMG_5942.jpg' },
        { image: 'ahsap-kutu-17.jpg' },
        { image: 'IMG_5947.jpg' },
        { image: 'IMG_5953.jpg' },
        { image: 'IMG_5961.jpg' },
        { image: 'ahsap-kutu-18.jpg' },
        { image: 'IMG_5975.jpg' },
        { image: 'ahsap-kutu-19.jpg' },
        { image: 'IMG_5979.jpg' },
        { image: 'IMG_5985.jpg' },
        { image: 'ahsap-kutu-20.jpg' },
        { image: 'IMG_5995.jpg' },
        { image: 'IMG_6020.jpg' },
        { image: 'IMG_6031.jpg' },
        { image: 'ahsap-kutu-21.jpg' },
        { image: 'IMG_6051.jpg' },
        { image: 'IMG_6054.jpg' },
        { image: 'ahsap-kutu-22.jpg' },
        { image: 'IMG_6060-copy.jpg' },
        { image: 'ahsap-kutu-23.jpg' },
        { image: 'ahsap-kutu-24.jpg' },
        { image: 'ahsap-kutu-25.jpg' },
        { image: 'IMG_6067.jpg' },
        { image: 'ahsap-kutu-26.jpg' },
        { image: 'ahsap-kutu-27.jpg' },
        { image: 'IMG_6069.jpg' },
        { image: 'ahsap-kutu-28.jpg' },
        { image: 'ahsap-kutu-29.jpg' },
        { image: 'ahsap-kutu-30.jpg' },
        { image: 'ahsap-kutu-31.jpg' },
        { image: 'ahsap-kutu-32.jpg' },
        { image: 'ahsap-kutu-33.jpg' },
        { image: 'ahsap-kutu-34.jpg' },
        { image: 'ahsap-kutu-35.jpg' },
        { image: 'ahsap-kutu-36.jpg' },
        { image: 'ahsap-kutu-37.jpg' },
        { image: 'ahsap-kutu-38.jpg' },
      ],
    };
  },
};
</script>

<style lang="postcss"></style>
