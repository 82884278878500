<template>
  <div class="contact">
    <p
      v-for="(p, i) in contact"
      :key="i"
      class="georgia text-xl leading-relaxed text-center pb-4"
      :class="i === 0 ? 'font-extrabold' : 'italic'"
      >
      {{ p.label === 'title' ? p.value : null }}
      {{ p.label === 'phone' ? p.value : null }}

      <a
        v-if="p.label === 'email'"
        :href="`mailto:${p.value}`"
        >
        {{ p.value }}
      </a>
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      contact: [
        {
          label: 'title',
          value: 'İletişim',
        },
        {
          label: 'phone',
          value: '+90 532 113 99 69',
        },
        {
          label: 'phone',
          value: '‪+90 536 420 34 14‬',
        },
        {
          label: 'email',
          value: 'nuhungemisi.50@gmail.com',
        },
        {
          label: 'email',
          value: 'fatih_tym@hotmail.com',
        },
      ],
    };
  },
};
</script>

