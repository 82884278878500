<template>
  <div
    id="app"
    class="bg-primary h-full"
    >
    <div class="max-w-5xl mx-auto px-4">
      <header class="flex flex-col md:flex-row text-white py-4 border-b items-center">
        <h1 class="text-5xl font-semibold tracking-tighter leading-normal flex-1">
          <router-link
            v-slot="{ href, route, navigate, isActive }"
            to="/"
            custom
            >
            <a
              :active="isActive"
              :href="href"
              @click="navigate"
              >
              Özgür Ahşap Kutu
            </a>
          </router-link>
        </h1>

        <nav class="flex items-center font-normal text-xl justify-around md:justify-end py-5">
          <ul
            v-for="(item, i) in nav"
            :key="i"
            >
            <li>
              <router-link
                v-slot="{ href, route, navigate, isActive }"
                :to="`/${item.path}`"
                custom
                >
                <a
                  :active="isActive"
                  :href="href"
                  class="p-4 hover:bg-white hover:text-primary"
                  @click="navigate"
                  >
                  {{ item.name }}
                </a>
              </router-link>
            </li>
          </ul>
        </nav>
      </header>

      <p class="text-xl font-normal leading-normal georgia py-8">
        {{ intro }}
      </p>

      <router-view class="" />

      <footer class="text-center py-8 border-t">
        <p>© Copyright {{ year }} | Özgür Ahşap Kutu | Tüm hakları saklıdır. Hiç bir görsel izinsiz kullanılamaz.</p>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nav: {
        about: {
          name: 'Hakkımızda',
          path: 'hakkimizda',
        },
        production: {
          name: 'Üretim',
          path: 'uretim',
        },
        contact: {
          name: 'İletişim',
          path: 'iletisim',
        },
      },
      intro:
        'Sunumları daha zarif kılmak, değerini arttırmak ve karşınızdakine verdiğiniz hediyenin önemini vurgulamak açısından ambalajın değeri tartışılmaz. Bu konuya yeni bir bakış açısı kazandırmak adına üretime başlayan “Özgür Ahşap” “ahşap kutu” ambalaj sanayii ve ticaret limited şirketi isteyenlere sınırsız seçenek sunuyor.',

    };
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
};
</script>


<style lang="postcss">
#app {
  font-family: Helvetica, Arial, sans-serif;
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
  @apply text-white;
}

body {
  @apply bg-primary;
}

a {
  @apply hover:bg-white hover:text-primary;
}
.georgia {
  font-family: "Georgia";
}

</style>
