var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-primary h-full",attrs:{"id":"app"}},[_c('div',{staticClass:"max-w-5xl mx-auto px-4"},[_c('header',{staticClass:"flex flex-col md:flex-row text-white py-4 border-b items-center"},[_c('h1',{staticClass:"text-5xl font-semibold tracking-tighter leading-normal flex-1"},[_c('router-link',{attrs:{"to":"/","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{attrs:{"active":isActive,"href":href},on:{"click":navigate}},[_vm._v(" Özgür Ahşap Kutu ")])]}}])})],1),_c('nav',{staticClass:"flex items-center font-normal text-xl justify-around md:justify-end py-5"},_vm._l((_vm.nav),function(item,i){return _c('ul',{key:i},[_c('li',[_c('router-link',{attrs:{"to":("/" + (item.path)),"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{staticClass:"p-4 hover:bg-white hover:text-primary",attrs:{"active":isActive,"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)})],1)])}),0)]),_c('p',{staticClass:"text-xl font-normal leading-normal georgia py-8"},[_vm._v(" "+_vm._s(_vm.intro)+" ")]),_c('router-view',{}),_c('footer',{staticClass:"text-center py-8 border-t"},[_c('p',[_vm._v("© Copyright "+_vm._s(_vm.year)+" | Özgür Ahşap Kutu | Tüm hakları saklıdır. Hiç bir görsel izinsiz kullanılamaz.")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }